import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import { styled } from "../../../stitches.config"
import { SEO } from "../../components/Seo/Seo"

const Separator = styled("span", {
  length: 0,
  color: "$slate11",
  backgroundColor: "$slate1",
  display: "inline-block",
  fontFamily: "$default",
  fontSize: "$base",
  margin: "0 1px",

  "&::before": {
    content: "' '",
  },
})

const MenuItem = ({ text }) => (
  <div
    style={{
      display: 'inline-block',
      backgroundColor: 'var(--colors-slate1)',
      borderRadius: '8px',
      padding: '0.3rem',
      transition: 'background-color 0.2s ease-in-out',
      cursor: 'pointer',
      fontSize: '17px',
      fontWeight: 'bold'
    }}
    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'var(--colors-slate4)')}
    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'var(--colors-slate1)')}
  >
    {text}
  </div>
);

const Container = styled("nav", {
  length: 0,
  alignItems: "center",
  backgroundColor: "$slate1",
  color: "$slate11",
  display: "flex",
  paddingBottom: "$8",
})

const Navigation = () => {
  const data = useStaticQuery(graphql`
    query {
      resume: file(relativePath: { eq: "files/matheus-tura-resume.pdf" }) {
        publicURL
      }
    }
  `)

  return (
    <Container>
      <Link activeStyle={{ color: "#FFF" }} to="/">
        <MenuItem text="Home"/>
      </Link>

      <Separator/>
      <Link activeStyle={{ color: "#FFF" }} to="/about">
        <MenuItem text="About"/>
      </Link>

      <Separator/>
      <Link activeStyle={{ color: "#FFF" }} to="/work">
        <MenuItem text="Work"/>
      </Link>

      <Separator/>
      <Link activeStyle={{ color: "#FFF" }} to="/blog">
        <MenuItem text="Blog"/>
      </Link>

      <Separator/>      
      <a href={data.resume.publicURL} aria-label="Resume">
        <MenuItem text="Resume"/>
      </a>
      
      <Separator/>
      <Link activeStyle={{ color: "#FFF" }} to="/contact">
        <MenuItem text="Contact"/>
      </Link>
    </Container>
  )
}

export { Navigation }

export const Head = () => (
  <SEO title="navigation"/>
)
